<template>
  <v-container fluid class="wrapper d-flex align-center">
    <v-layout class="centered">
      <v-flex xs12 sm12 md6 lg6>
        <v-card>
          <v-card-text>
            <div v-show="!error">
              <v-progress-linear :indeterminate="true"></v-progress-linear>
              Verifying template...
            </div>
            <div v-if="error">          
              <v-alert type="error" dense outlined>
                {{ error }}
              </v-alert>
            </div>
          </v-card-text>
          <v-card-actions v-if="error">
            <v-spacer> </v-spacer>
            <p>
              Failed to prepare application deployment template from the URL. Button might have incorrect
              filename provided or malformed manifest.
            </p>            
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// This component is just a redirect place where we can push the user into
// a selected project and namespace.

export default {
  data() {
    return {
      error: null,
      fileUrl: this.$route.query.fileUrl
    }
  },

  computed: {
    loading() {
      return this.$store.state.application.loading
    },
    applicationError() {
      return this.$store.state.application.error
    },
    selectedProjectId() {
      return this.$store.state.project.selectedProjectId
    },
    selectedNamespace() {
      return this.$store.state.namespace.selectedNamespaceId
    }
  },

  mounted() {

    if (!this.fileUrl) {
      this.error = `Missing ?fileUrl=https://example.com/template.yaml parameter`
      return
    }    

    this.loadAndRedirect()
  },

  // This is needed because we are loading data async. Project
  // and namespace are being loaded in the background in the main App.vue
  // component. This must trigger the retries both times.
  watch: {
    'selectedProjectId'() {
      this.loadAndRedirect()
    },
    'selectedNamespace'() {
      this.loadAndRedirect()
    },
  },

  methods: {
    async loadAndRedirect() {
      if (!this.selectedProjectId) {        
        return
      }

      if (!this.selectedNamespace) {       
        return
      }

      await this.$store.dispatch('GetApplicationDeploymentTemplate', {
        fileUrl: this.fileUrl,
      })

      if (this.applicationError) {
        this.error = this.applicationError
        return
      }
      
      // Redirecting
      this.$router.push({ 
        name: 'newApplication', 
        params: {
          project: this.selectedProjectId,
          namespace: this.selectedNamespace
        },
        query: { fileUrl: this.fileUrl }}
      )

    }
  }
}
</script>